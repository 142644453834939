import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
// import { faCheckCircle, faChevronRight, faPlay } from '@fortawesome/free-solid-svg-icons'

export const bannerContent = {
  title: "Subscription billing that won't suck the life out of you",
  description: [
    {
      text: 'The most powerful, customizable and easy to integrate subscription billing software used by hundreds of companies worldwide to simplify revenue operations'
    }
  ],
  // withPromotion: true,
  // promotionBadge: "Offer",
  // promotionTitle: <>Enjoy a <span className="bold">$5,000 free trial</span> when you sign up today - no credit card required</>,
  textColor: "#fff",
  backgroundColor: "#243F5C",
  // backgroundImage: require('../images/carbon-dracula.svg'),
  svg: true,
  image: require('../images/carbon-dracula.svg'),
  // srcSet:'home-hero@2x.png 2x',
  imageName: 'billsby carbon banner image',
  imagePosition: 'right',
  sectionHomeBanner: true,
  hasBg: true,
  sectionCarbonBanner: true,
  isBanner: true,
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonText: 'Sign up today',
      buttonColor: 'orange',
      isExternal: true
    },
    {
      scheduleButton: true
    }
  ]
}

export const comparisonTableContent = {
  title: 'How we stack up',
  description: "With incredible features included for everyone – and no features you need for your business to succeed locked behind expensive plans – we’ve made sure that Billsby is the most competitive recurring payments software around.",
  heading: [
    {
      logo: 'billsby_logo_blue.png',
      title: 'Billsby' 
    },
    {
      logo: 'chargebee.png',
      title: 'Chargebee' 
    },
    {
      logo: 'chargify.png',
      title: 'Chargify' 
    },
    {
      logo: 'recurly.png',
      title: 'Recurly' 
    },
    {
      logo: "stripe-billing-logo-01.png",
      title: "Stripe Billing",
    }
  ],
  body: [
    {
      title: 'Monthly fee',
      subtitle: "base plan",
      content: [
        {
          itemContent: "$35",
        },
        {
          itemContent: "From $299",
        },
        {
          itemContent: "From $599",
        },
        {
          itemContent: "From $149",
        },
        {
          itemContent: "$0",
        },
      ]
    },
    {
      title: "Overage transaction fee",
      content: [
        {
          itemContent: "0.4%",
        },
        {
          itemContent: "Up to 0.75%",
        },
        {
          itemContent: "Up to 0.9%",
        },
        {
          itemContent: "Up to 0.9%",
        },
        {
          itemContent: "Up to 2.9% plus 30 cents",
        },
      ]
    },
    {
      title: 'Usage counters',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: ''
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: "",
        },
      ]
    },
    {
      title: 'Feature tags',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        }
      ]
    },
    {
      title: "Advanced retention tools",
      content: [
        {
          itemContent: (
            <FontAwesomeIcon icon={faCheckCircle} className="icon-check" />
          ),
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
      ],
    },
    {
      title: 'Plan actions',
      subtitle: 'coming soon',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        }
      ]
    },
    {
      title: 'Value scoring',
      subtitle: 'coming soon',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        }
      ]
    },
  ],
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      text: 'Sign up today',
      isExternal: true
    },
    {
      scheduleButton: true,
      buttonColor: 'black'
    }
  ]
}

export const trialContent = {
  title: "Get started with Billsby today",
  description:
    <>You can sign up to Billsby for as little as $35 per month, including $15,000 per month in transaction value. <br /> The best way to get to know Billsby is to try it for yourself.</>,
  svg: true,
  image: require("../images/free-trial.svg"),
  imageName: 'free-trial image',
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: "Get started with your account today",
      isExternal: true
    },
    {
      scheduleButton: true,
      buttonColor: 'black'
    }
  ]
}

export const planActionContent = {
  title: "Power your subscriptions with Zapier.",
  description: "Once customers sign-up and as their subscription changes, use Zapier to complete follow up tasks across all of the other tools and services you use to run your business.",
  svg: true,
  image: require('../images/plan-action.svg'),
  imageName: 'plan action image',
  cards: [
    {
      image: 'plan-action-1.png',
      text: 'Add the new customer to your CRM system',
      svgLogo: true,
      logo: require('../images/pipedrive.svg'),
      logoName: 'pipedrive'
    },
    {
      image: 'plan-action-2.png',
      text: 'Subscribe them to the correct mailing list',
      logo: 'mailchimp.png',
      logoName: 'mailchimp'
    },
    {
      image: 'plan-action-3.png',
      text: 'Post out their welcome pack',
      logo: 'shipwire.png',
      logoName: 'shipwire'
    }
  ],
  url: '/product/integrations/zapier',
  buttonColor: 'blue',
  buttonText: 'Learn about Zapier',
  comingSoonText:''
}

export const customerJourneyContent = {
  title: "Deliver a great experience throughout the customer journey.",
  list: [
    {
      title: 'Get customers signed up',
      description: <>Our <Link to="/product/subscribers/checkout-and-payments">drop-in checkout</Link> helps customers to sign up for your product quickly and easily, with low development effort.</>,
    },
    {
      title: 'Self-service account management',
      description: <>Customers can manage their own account with our <Link to="/product/subscribers/account-management">drop-in account management tools</Link> - reducing service costs.</>,
    },
    {
      title: 'Keep customers from leaving',
      description: <>When your customers think about leaving, our <Link to="/product/subscribers/dunning-retention">retention and promotion tools</Link> can stop them and help reduce churn.</>,
    }
  ],
  svg: true,
  image: require('../images/customer-journey2.svg'),
  imageName: 'customer journey'
}

export const developersContent = {
  title: "Developers love Billsby because it's easier to integrate and maintain.",
  description: [
    {
      text: "From the start, we've built Billsby to balance the commercial desires of businesses to introduce new offers and plans frequently with developers' needs to not constantly have to update their product to support these business changes. From feature tags to real-time usage counters, Billsby enables flexibility you won't find elsewhere."
    }
  ],
  buttons: [
    {
      url: '/product/developers',
      buttonColor: 'white',
      buttonText: 'Discover Billsby for developers'
    }
  ],
  sectionDevelopers: true,
  image: 'home-developers.png',
  imageName: 'developers svg',
  backgroundColor: '#f3f5fb',
  textColor: '#000',
  imagePosition: 'right'
}

export const featureTags = {
  title: "Feature Tags make access control and permissions a breeze.",
  backgroundColor: '#fff',
  list: [
    {
      title: 'With other billing platforms, you grant permissions and access based on plans',
      description: <> This sounds fine to start with - but it doesn't scale. As your business grows, you'll amass hundreds of plans and need to accommodate edge cases - like giving certain customers access to extra features, or managing permissions for beta tests or just to quickly QA whether things are working as you'd expect.</>,
    },
    {
      title: 'With Billsby, you grant permissions and access with Feature Tags',
      description: <> Feature Tags can be associated with plans, add-ons and allowances - or individual customers. So, for example, you can check for the presence of #spa on a customers account to determine whether they should be let into the spa, or #whitelabel to determine if they should be able to use your white label feature.</>,
    },
  ],
  image: require('../images/billsby-feature-tags.svg'),
  imageName: 'billsby-order-hierarchy',
  svg: true,
  btnText: 'Feature Tags',
  btnClass: 'adoric_video_featuretags'
}

export const trackUsage = {
  title: "Track usage in real-time, without any effort on your end.",
  backgroundColor: '#fff',
  list: [
    {
      title: 'With other billing platforms, you keep track of usage ',
      description: <>So if you're billing for something like minutes or units each month, you need to spin up a system to count these minutes through the month, then once the month is over, the customers invoice is suspended until you report the usage back, and then billing happens.</>,
    },
    {
      title: 'With Billsby, you can send usage information in real time',
      description: <>Send usage to us as soon as it's consumed, and we'll keep track in real time. You can request usage balances whenever you'd like and use this to build advanced system behaviours - and because we know about overage ahead of time, you can see and manage it in the control panel.</>,
    },
  ],
  align: 'right',
  svg: true,
  image: require('../images/addons-allowances.svg'),
  imageName: 'addons allowances',
  btnText: 'Metered Allowances',
  btnClass: 'adoric_video_allowances',
}

export const orderHierarchy = {
  title: "Products, plans and cycles give you the order and hierarchy you need",
  backgroundColor: '#fff',
  list: [
    {
      title: 'With other billing platforms, everything is a plan',
      description: <>This lack of structure means you have to understand at your end that some plans are different and others are just variants of the same thing - like a monthly and annual cycle length. This makes it very difficult to create dynamic pricing tables and pages.</>,
    },
    {
      title: 'With Billsby, everything is logically structured',
      description: <>By structuring your plan options as products, plans and cycles, and associating feature tags with those options, we make it possible for you to dynamically generate all of your pricing tables and marketing pages - which means plans can change at any time with no development effort or changes required.</>,
    },
  ],
  svg: true,
  image: require('../images/structured-plans.svg'),
  imageName: 'billsby-order-hierarchy',
  btnText: 'Products, Plans and Cycles',
  btnClass: 'adoric_video_products',
}